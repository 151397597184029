import { post1, get1 } from '../http.js'
export default {
  getPanicbuy: (params) => {
    return get1('/v10mogul/panicbuy/list', params)
  },
  startBuy: (params) => {
    return post1('/v10mogul/panicbuy', params)
  },
  panicbuyRecord: (params) => {
    return get1('/v10mogul/panicbuy/record', params)
  },
  panicbuyPage: (params) => {
    return get1('/v10mogul/panicbuy/skins', params)
  },
  panicbuyId: (params) => {
    return get1(`/v10mogul/panicbuy/skin/${params.id}`)
  }

}

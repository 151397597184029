import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
// import config from '../../config'
// import modules from './modules/index'
Vue.use(Vuex)
// const a = config[process.env.APP_NAME]
// console.log(process.env)
const state = {
  token: 'eyJhbGciOiJIUzI1NiIsImtpZCI6ImtpdHR5IiwidHlwIjoiSldUIn0.eyJleHAiOjE2NzE3NjYzMzYsImlhdCI6MTY2OTE3NDMzNiwiaXNzIjoia2l0dHkiLCJQYWNrYWdlTmFtZSI6ImNvbS50YWloZS5tZXRhdmVyc2UuZGlzY292ZXIiLCJVc2VySWQiOjU5NDc5MzM2LCJTdXVpZCI6IkRvTmV3c2YzMTI0MDdmLWNjMDMtNDg3YS1iODg0LTFlMDZhZjBkYWZiMCIsIkNoYW5uZWwiOiJ5bHRzIiwiVmVyc2lvbkNvZGUiOiIxMDAyOCIsIlJlZ2lzdGVyVGltZSI6MTY2OTE2OTkwOX0.rH2SHwYHFj-pnF_pUOfBmRAQmXjVr9XKuyUhJWm0F3Q',
  commonParam: {
    uid: 50916417,
    version_code: 10000,
    guide_status: '0',
    number: 0,
    time: '1653638400',
    animation: '4'
  }
}

export default new Vuex.Store({
  state,
  mutations: {
    setToken(state, res) {
      state.token = res
    },
    setCommonParam(state, res) {
      Object.keys(res).forEach(key => {
        state.commonParam[key] = res[key]
      })
    },
    setActiveNum(state, res) {
      console.log(res)
      state.activeNum = res
    },
    setAddress(state, res) {
      Object.keys(res).forEach(key => {
        state.addressInfo[key] = res[key]
      })
    },
    setBlindBox(state, res) {
      console.log('setAddress', res)
      Object.keys(res).forEach(key => {
        state.blindBoxInfo[key] = res[key]
      })
      console.log(state.blindBoxInfo)
    }

  },
  actions: {
  },
  // modules,
  plugins: [
    new VuexPersistence({
      // storage: window.localStorage,
      reducer: (state) => ({
        token: state.token,
        commonParam: state.commonParam,
        activeNum: state.activeNum
      })
    }).plugin
  ]
})

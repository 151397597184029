import { post1, get1, get2, get3 } from '../http.js'
export default {
  getSkin: (params) => {
    return post1('/v10mogul/clockInSkins', params)
  },
  invitationInfo: (params) => {
    return get1('/v10mogul/getInviteReward', params)
  },
  friendList: (params) => {
    return get1('/v10mogul/getIncomes', params)
  },
  selectedSkin: (params) => {
    return post1('/v10mogul/startClockIn', params)
  },
  reselectSkin: (params) => {
    return post1('/v10mogul/changeClockInReward', params)
  },
  getSignList: (params) => {
    return get1('/v10mogul/getClockInSkinInfo', params)
  },
  getSlide: (params) => {
    return get1('/v10mogul/getExchangeInfo', params)
  },
  exRecord: (params) => {
    return post1('/v10mogul/getExchangeList', params)
  },
  goldDetail: (params) => {
    return get2('/xtasks/score/detail', params)
  },
  walletCoin: (params) => {
    return get2('/xtasks/score/query', params)
  },
  serverInfo: (params) => {
    return get1(`/v10mogul/user/area/${params.id}`)
  },
  saveSkinInfo: (params) => {
    return post1('/v10mogul/user/area', params)
  },
  getSkinGold: (params) => {
    return post1('/v10mogul/addAction', params)
  },
  exCardSkin: (params) => {
    return post1('/v10mogul/exchangeClockInReward', params)
  },
  exchangeSkin: (params) => {
    return post1('/v10mogul/exchangeSkin', params)
  },
  getInfo: (params) => {
    return get3(`/app/v2/info/${params.id}`)
  },
  getShareUrl: (params) => {
    return get3('/share/v1/url', params)
  },
  getInvCode: (params) => {
    return get3('/share/v1/code', params)
  },
  getInvText: (params) => {
    return get3('/rule/v1/calculate/v10mogul-ruleCopy-prod', params)
  }
}

import { get1 } from '../http.js'
export default {
  getRedPacketConfig: (params) => {
    return get1('/ylts/common/rain/config', params)
  },
  redPacket: (params) => {
    return get1('/ylts/common/rain/report', params)
  },
  getQuestionList: (params) => {
    return get1('/ylts/common/answer/config', params)
  }
}

import Vue from 'vue'
import './vant'
import App from './App.vue'
import router from './router'
import store from './store'
import VueClipboard from 'vue-clipboard2'
import './assets/theme/reset.css'
import './assets/index.scss'
import api from './server/api/index.js'
import Vconsole from 'vconsole'
import 'amfe-flexible'

if (process.env.VUE_APP_ENV && process.env.VUE_APP_ENV === 'test') {
  var vConsole = new Vconsole()
  Vue.use(vConsole)
}
Vue.prototype.$api = api

Vue.config.productionTip = false

Vue.use(VueClipboard)
const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
export { app }

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '助推器'
    }
  },
  {
    path: '/applist',
    name: 'applist',
    component: () => import(/* webpackChunkName: "exchange" */ '../views/applist.vue'),
    meta: {
      title: '软件列表'
    }
  },
  {
    path: '/record',
    name: 'record',
    component: () => import(/* webpackChunkName: "exchange" */ '../views/record.vue'),
    meta: {
      title: '提现记录'
    }
  },
  {
    path: '/mileage',
    name: 'mileage',
    component: () => import(/* webpackChunkName: "exchange" */ '../views/mileage/mileage.vue'),
    meta: {
      title: '我的收益'
    }
  },
  {
    path: '/conversion',
    name: 'conversion',
    component: () => import(/* webpackChunkName: "exchange" */ '../views/mileage/conversion.vue'),
    meta: {
      title: '里程兑换'
    }
  },
  {
    path: '/cabin',
    name: 'cabin',
    component: () => import(/* webpackChunkName: "exchange" */ '../views/cabin.vue'),
    meta: {
      title: '我的飞船'
    }
  },
  {
    path: '/invitation',
    name: 'invitation',
    component: () => import(/* webpackChunkName: "exchange" */ '../views/invitation.vue'),
    meta: {
      title: '邀请舰长'
    }
  },
  {
    path: '/sharepage',
    name: 'invitation',
    component: () => import(/* webpackChunkName: "exchange" */ '../views/sharepage.vue'),
    meta: {
      title: '邀请好友'
    }
  },
  {
    path: '/privacy',
    name: 'invitation',
    component: () => import(/* webpackChunkName: "exchange" */ '../views/privacy.vue'),
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/lottery',
    name: 'lottery',
    component: () => import(/* webpackChunkName: "exchange" */ '../views/lottery.vue'),
    meta: {
      title: '转盘抽奖'
    }
  },
  {
    path: '/red/packet/rain',
    name: 'RedPacketRain',
    component: () => import(/* webpackChunkName: "exchange" */ '../views/redPacketRain.vue'),
    meta: {
      title: '红包雨'
    }
  },
  {
    path: '/question',
    name: 'Question',
    component: () => import(/* webpackChunkName: "exchange" */ '../views/question.vue'),
    meta: {
      title: '高收益检测'
    }
  },
  {
    path: '/slas',
    name: 'invitation',
    component: () => import(/* webpackChunkName: "exchange" */ '../views/slas.vue'),
    meta: {
      title: '用户协议'
    }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router

import { transformStr3 } from '../../until/index'

const files = require.context('.', true, /\.(js)|(ts)$/)
let API = {}
files
  .keys()
  .forEach((key) => {
    if (key === './index.js') { return }
    const moduleData = files(key).default
    const replacedKey = key.replace(/(\.\/|\.(js)|\.(ts))/g, '')
    let resetObject = moduleData
    if (replacedKey.includes('/')) {
      resetObject = {}
      Object.keys(moduleData).forEach(item => {
        resetObject[transformStr3(replacedKey + '/' + item)] = moduleData[item]
      })
    }
    const obj = {}
    obj[replacedKey] = resetObject
    API = Object.assign(API, obj)
  })
console.log(API)
export default API

import { get1, get2, get3, put3, post1 } from '../http.js'
export default {
  // home页面的任务列表
  getTaskList: (params) => {
    return get2('/v1/task/list', params)
  },
  // 没用上
  getMoneyList: (params) => {
    return get2('/v2/deposit/logs', params)
  },
  // 之前明细多
  // getTotalList: (params) => {
  //   return post2('/v2/score/query', params)
  // },
  // 之前明细单独
  getTotalList2: (params) => {
    return get2('/v2/score/detail', params)
  },
  // 用户里程和金额
  getUserInfo: (params) => {
    return get2('/v2/score/get', params)
  },
  getUserCode: (params) => {
    return get3(`/app/v2/info/${params.id}`)
  },
  getUserInfoDetail: (params) => {
    const { id, ...data } = params
    return get3(`/app/v2/info/${id}`, data)
  },
  putInviteCode: (params) => {
    return put3('/share/v1/code', params)
  },
  getAppList: (params) => {
    return get1('/ylts/config', params)
  },
  postDistance: (params) => {
    return post1('/ylts/convert', params)
  },
  getFriendsList: (params) => {
    return get1('/ylts/common/contribution/list', params)
  },
  getMessage: (params) => {
    return get1('/ylts/some/last', params)
  },
  putMessage: (params) => {
    return post1('/ylts/some/report', params)
  },
  luckLottery: (params) => {
    return get1('ylts/luck/config', params)
  },
  getNewNumber: (params) => {
    return get1('/ylts/some/last', params)
  },
  getNewTimeNumber: (params) => {
    return get3('/rule/v1/calculate/ylts-app-common-config-prod', params)
  },
  getManager: (params) => {
    return get1('/ylts/user/new/withdraw/manager', params)
  }
}

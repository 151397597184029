import { post1, get1 } from '../http.js'
export default {
  getGiftDetail: (params) => {
    return get1(`/v10mogul/gift/detail/${params.id}`)
  },
  getInfo: (params) => {
    return get1('/v10mogul/gift/userinfo', params)
  },
  updateInfo: (params) => {
    return post1('/v10mogul/gift/userinfo', params)
  },
  createOrder: (params) => {
    return post1('/v10mogul/gift/orders', params)
  }

}

import axios from 'axios'
import md5 from 'js-md5'
import qs from 'qs'

import store from '@/store/index'
// import { InternetError, getDeviceType, getCode, aesEncrypt, randomString } from '@/until/util.js'

import { app } from '@/main.js'
console.log(store.state.token)

export const TIMEOUT = 60 * 1000
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.timeout = TIMEOUT
// axios.defaults.withCredentials = true

axios.defaults.retry = 3
axios.defaults.retryDelay = 2000
const config = {
  baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_URL
}
const config2 = {
  baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API2 : process.env.VUE_APP_BASE_URL2
}
const config3 = {
  baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API3 : process.env.VUE_APP_BASE_URL3
}
const _axios = axios.create(config)
const _axios2 = axios.create(config2)
const _axios3 = axios.create(config3)
// const configs = require('../../config')
// const edition = configs[process.env.APP_NAME]
// eslint-disable-next-line
function interceptors (axios) {
  axios.interceptors.request.use(
    config => {
      const token = store.state.token
      config.headers = {
        ...config.headers,
        Authorization: 'Bearer ' + token
      }
      if (config.method === 'post') {
        config.headers['i-token'] = md5(JSON.stringify(config.data))
      }
      if (config.url === '/v2/deposit/logs') {
        config.paramsSerializer = function(params) {
          return encodeURI(qs.stringify(params, { arrayFormat: 'repeat' }))
        }
      }
      return config
    },
    error => {
      return Promise.error(error)
    }
  )
  axios.interceptors.response.use(
    response => {
      if (response.status >= 200 && response.status < 399) {
        return Promise.resolve(response)
      } else {
        return Promise.reject(response)
      }
    },
    error => {
      /* eslint-disable prefer-promise-reject-errors  */
      console.log('====error===', error)
      const { response } = error
      if (response) {
        return Promise.reject(response)
      } else {
        return Promise.reject(Promise.reject({
          requestCode: 7001,
          msg: '请求超时，请检查网络连接是否正常'
        }))
      }
    }
  )
}
interceptors(_axios)
interceptors(_axios2)
interceptors(_axios3)
export function get1(url, params = {}) {
  return new Promise((resolve, reject) => {
    _axios.get(url, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      if (err.requestCode) {
        app.$toast(err.msg)
      }
      reject(err)
    })
  })
}
export function post1(url, params) {
  return new Promise((resolve, reject) => {
    _axios.post(url, params)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        if (err.requestCode) {
          app.$toast(err.msg)
        }

        reject(err)
      })
  })
}
export function get2(url, params = {}) {
  return new Promise((resolve, reject) => {
    _axios2.get(url, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      if (err.requestCode) {
        app.$toast(err.msg)
      }
      reject(err)
    })
  })
}
export function post2(url, params) {
  return new Promise((resolve, reject) => {
    _axios2.post(url, params)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        if (err.requestCode) {
          app.$toast(err.msg)
        }

        reject(err)
      })
  })
}
export function get3(url, params = {}) {
  return new Promise((resolve, reject) => {
    _axios3.get(url, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      if (err.requestCode) {
        app.$toast(err.msg)
      }
      reject(err)
    })
  })
}
// export function put3(url, params) {
//   return new Promise((resolve, reject) => {
//     _axios3.put(url, {
//       params
//     }).then(res => {
//       resolve(res.data)
//     }).catch(err => {
//       if (err.requestCode) {
//         app.$toast(err.msg)
//       }
//       reject(err)
//     })
//   })
// }

export function put3(url, params) {
  return new Promise((resolve, reject) => {
    _axios3.put(url, params)
      .then(res => {
        resolve(res.data)
        // Loading.service(true).close();
        //  Message({message: '请求成功', type: 'success'});
      })
      .catch(err => {
        reject(err.data)
        // Loading.service(true).close();
        // Message({ message: '加载失败', type: 'error' })
      })
  })
}
